/**
* Generated automatically at built-time (2024-12-03T08:28:53.110Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "weinkuehlschrank-black-friday",templateKey: "sites/85-e24ffd9a-ccc4-4087-9e8b-6cab78ed5605"};